import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Container } from '@qga/roo-ui/components';
import isEmpty from 'lodash/isEmpty';
import {
  getHomePageContent,
  getFooterLinksByCategory,
  getFeaturedCampaigns,
  getTermsAndConditions,
} from 'store/homePage/homePageSelectors';
import HomePageHelmet from './HomePageHelmet';
import HomePageSearchPanel from './HomePageSearchPanel';
import ListOfRegions from 'components/Content/ListOfRegions';
import RequestCallbackModal from 'components/RequestCallbackModal';
import ValuePropositionsList from 'components/Content/ValuePropositionsList';
// import CrossSellList from 'components/Content/CrossSellList';
import OffersList from 'components/Content/OffersList';
import PopularDestinationFooter from 'components/PopularDestinationFooter';
import FeaturedCampaign from './FeaturedCampaign';
import FeaturedCampaignsCarousel from 'components/Content/Carousel';
import TermsAndConditions from 'components/Content/TermsAndConditions';
import { getIsMobileApp } from 'store/ui/uiSelectors';
import CampaignMessaging from 'components/CampaignMessaging';

const HomePageLayout: FC = () => {
  const {
    valuePropositions: { title, valuePropositions, layout } = { title: '', valuePropositions: [], layout: '' },
    regionLinks = [],
    offers = {},
    // crossSells = {},
  } = useSelector(getHomePageContent) || {};
  const footerLinks = useSelector(getFooterLinksByCategory);
  const featuredCampaigns = useSelector(getFeaturedCampaigns);
  const termsAndConditions = useSelector(getTermsAndConditions);
  const isMobileApp = useSelector(getIsMobileApp);
  const hasTerms = !isEmpty(termsAndConditions);

  return (
    <>
      <HomePageHelmet />
      <CampaignMessaging showDefaultMessage={true} />
      <HomePageSearchPanel />

      <RequestCallbackModal interactionType="Homepage search" />

      <div id="main-content"></div>
      {!isMobileApp && (
        <>
          <Container mt={[8, 14, 15]} mb={[12, 21, 15]} gutter={[4, 6]} maxWidth={1284}>
            <ValuePropositionsList title={title} layout={layout ?? 'slimline'} valuePropositions={valuePropositions} />
          </Container>

          <Container mb={[20, 10, 15]} px={[0, 6]} maxWidth={1284}>
            <FeaturedCampaignsCarousel itemRenderer={FeaturedCampaign} itemList={featuredCampaigns} skipToContent="#offers-list-content" />
          </Container>

          <div id="offers-list-content"></div>

          <div style={{ background: 'white' }}>
            <Container mb={[9, 9, 15]} pt={[9, 15, 15]} pb={[9, 15, 11]} gutter={[4, 6]} maxWidth={1284}>
              <OffersList {...offers} />
            </Container>
          </div>

          <Container mb={[4, 4, 15]} gutter={[4, 6]} maxWidth={1284}>
            <ListOfRegions {...regionLinks} title={regionLinks.title} />
          </Container>

          <div style={{ background: 'white' }}>
            <Container mb={0} pt={[12, 6, 15]} gutter={[4, 6]} maxWidth={1284}>
              <PopularDestinationFooter links={footerLinks} />
              <div style={{ borderBottom: '1px solid #D9D9D9' }} />
            </Container>
          </div>

          {hasTerms && <TermsAndConditions termsAndConditions={termsAndConditions} />}
        </>
      )}
    </>
  );
};

export default HomePageLayout;
