/* eslint-disable prettier/prettier */
import React from 'react';
import Head from 'next/head';
import { useSelector } from 'react-redux';
import { getHomePageMeta } from 'store/homePage/homePageSelectors';
import { HOTELS_URL } from 'config';

type Props = {
  title?: string;
  description?: string;
  tags?: {
    content: string;
    name: string;
  }[];
  targetRef?: React.RefObject<HTMLElement>;
};

// Double quotes are necessary for the schema to be valid
const SCHEMA = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Qantas Hotels",
  "url": "https://www.qantas.com/hotels",
  "logo": "https://www.qantas.com/content/dam/qbr/partners/logos/qantas_hotels.svg",
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "1300 738 206",
    "contactType": "customer service",
    "contactOption": "TollFree",
    "areaServed": "AU",
    "availableLanguage": "en"
  },
  "sameAs": [
    "https://www.instagram.com/Qantas/",
    "https://www.youtube.com/user/Qantas",
    "https://www.facebook.com/Qantas/"
  ]
};

const HomePageHelmet = () => {
  const { title = 'Qantas Hotels', description, tags = [] }: Props = useSelector(getHomePageMeta);
  return (
    <Head>
      <title>{title}</title>
      <link rel="canonical" href={HOTELS_URL} />
      {description && <meta name="description" content={description} />}
      {tags && tags.map(({ name, content }) => <meta key={name} name={name} content={content} />)}

      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(SCHEMA) }} />
    </Head>
  );
};

export default HomePageHelmet;
