import { createSelector } from '@reduxjs/toolkit';
import reject from 'lodash/reject';
import isEmpty from 'lodash/isEmpty';

export const getHomePageContent = (state) => state?.homePage?.homePage || {};
export const getHomePageMeta = createSelector(getHomePageContent, (homePage) => homePage?.meta || {});
export const getHomePageBanner = createSelector(getHomePageContent, (homePage) => homePage?.defaultBanner || {});

export const getFeaturedCampaigns = createSelector(getHomePageContent, ({ featuredCampaigns = [] }) => {
  if (isEmpty(featuredCampaigns)) {
    return [];
  }
  return featuredCampaigns.slice(0, 5);
});

export const getFooterLinksByCategory = createSelector(getHomePageContent, ({ footerLinks = [] }) => {
  if (isEmpty(footerLinks)) {
    return [];
  }
  return footerLinks.map(({ title, links }) => ({
    title: title,
    linkList: !links
      ? []
      : links.map((link) => ({
          trackingName: link.fullName,
          children: link.name,
          to: link?.url ? link.url : `/search/list?location=${link.fullName}`,
        })),
  }));
});

export const getTermsAndConditions = createSelector(getHomePageContent, ({ termsAndConditions = {} }) => {
  const allTermsAndConditions = Object.values(termsAndConditions).flat();
  return reject(allTermsAndConditions, isEmpty);
});
