import React from 'react';
import { Flex, Heading } from '@qga/roo-ui/components';
import { Offer as OfferProps } from 'types/content';
import Offer from './Offer';
interface Props {
  title?: string;
  offers?: OfferProps[];
}

const OffersList = ({ title, offers = [] }: Props) => (
  <Flex flexDirection="column">
    <Heading.h2
      data-testid="offers-list-title"
      color="greys.charcoal"
      fontSize={['md', '28px', 'xl']}
      fontWeight={['bold']}
      lineHeight={[1.25]}
      mb={[8, 15]}
      textAlign="center"
      style={{ fontFamily: 'Jetstar' }}
    >
      {title}
    </Heading.h2>
    <Flex flexDirection={['column', 'row']}>
      {offers.slice(0, 3).map((offer, index) => (
        <Offer key={`offer-${index}`} {...offer} />
      ))}
    </Flex>
  </Flex>
);

export default OffersList;
