import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import PageBlock from 'components/PageBlock';
import TermsMarkdown from './TermsMarkdown';
import getMarkdownForBlockContent from 'lib/sanity/getMarkdownForBlockContent';
import getGroupedTerms from './utils/getGroupedTerms';
import { CONTENT_TERMS_ANCHOR_ID } from './index';

export const DisclaimerTitle = styled(Text)`
  color: ${themeGet('colors.greys.steel')};
  font-size: ${themeGet('fontSizes.sm')};
  margin: ${themeGet('space.2')} 0;
  font-weight: ${themeGet('fontWeights.bold')};
`;

const TermsDisclaimerItem = ({ disclaimer }) => {
  const mdContent = getMarkdownForBlockContent(disclaimer?.content);
  return <TermsMarkdown content={mdContent} />;
};

TermsDisclaimerItem.propTypes = {
  disclaimer: PropTypes.shape({
    content: PropTypes.array,
  }),
};

TermsDisclaimerItem.defaultProps = {
  disclaimer: {
    content: [],
  },
};

const TermsAndConditions = ({ termsAndConditions }) => {
  const groupedTerms = useMemo(() => getGroupedTerms(termsAndConditions), [termsAndConditions]);

  return (
    groupedTerms?.length > 0 && (
      <PageBlock id={CONTENT_TERMS_ANCHOR_ID} py={10} bg="white">
        <Text display="block" fontSize="base" fontWeight="bold" mb={3} mt={5} color="greys.steel">
          Offer Terms and Conditions
        </Text>

        {groupedTerms.map((item, index) => (
          <Flex flexDirection="column" key={`offer-terms-group-${item.title}-${index}`}>
            <DisclaimerTitle>
              <strong>{item.title}</strong>
            </DisclaimerTitle>

            {item.disclaimers.map(
              (disclaimer, index) => disclaimer && <TermsDisclaimerItem key={`offer-terms-disclaimer-${index}`} disclaimer={disclaimer} />,
            )}
          </Flex>
        ))}
      </PageBlock>
    )
  );
};

TermsAndConditions.propTypes = {
  termsAndConditions: PropTypes.array,
};

TermsAndConditions.defaultProps = {
  termsAndConditions: [],
};

export default TermsAndConditions;
