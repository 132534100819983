import React from 'react';
import { useSelector } from 'react-redux';
import { getHomePageBanner } from 'store/homePage/homePageSelectors';
import { Box, Text } from '@qga/roo-ui/components';
import { Tabs, TabList, Tab, TabPanel } from '@reach/tabs';
import { useRouter } from 'next/router';
import HotelsTab from './HotelsTab';
import AirbnbTab from 'components/AirbnbTab';
import { TabsWrapper, HeroImage, SearchWrapper, ResponsiveTabs, TabButton, TabPanels, LabelContainer } from './HomePageSearchPanel.style';
import { useDataLayer } from 'hooks/useDataLayer';
import { getImage } from 'lib/sanity';
import { PUBLIC_PATH } from 'config';
import { getIsMobileApp } from 'store/ui/uiSelectors';
import { rem } from 'polished';

const DEFAULT_IMAGE_SRC = `${PUBLIC_PATH}images/placeholder-desktop-image.jpg`;

const HomePageSearchPanel = () => {
  const router = useRouter();
  const { emitInteractionEvent } = useDataLayer();
  const heroImage = useSelector(getHomePageBanner);
  const isMobileApp = useSelector(getIsMobileApp);
  const heroImageSrc = getImage(heroImage?.asset);
  const isAirbnbSearch = router?.query?.searchType === 'airbnb';
  const defaultIndex = isAirbnbSearch ? 1 : 0;

  const onTabClicked = (tabIndex) => {
    // Keep track of what tab is selected using query strings
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { searchType, ...oldQuery } = router?.query || {};
    const newQuery = tabIndex === 1 ? { searchType: 'airbnb' } : {};
    router.push({ pathname: router.pathname || '/', query: { ...oldQuery, ...newQuery } }, undefined, { shallow: true });

    emitInteractionEvent({ type: 'Search', value: `Hotels ${tabIndex === 1 ? 'Airbnb ' : ''}Selected` });
  };

  return (
    <Box pt={120} position="relative">
      <HeroImage
        src={heroImageSrc?.xLarge || DEFAULT_IMAGE_SRC}
        role="img"
        aria-hidden="true"
        alt="Book hotels and accommodation in Australia"
        height={['221px', '249px', '250px']}
      />
      <TabsWrapper px={[4, 6]}>
        <Tabs onChange={onTabClicked} defaultIndex={defaultIndex} index={defaultIndex}>
          <TabList as={ResponsiveTabs}>
            <Tab as={TabButton} data-testid="hotels-tab" aria-label="Search for hotels" borderRadius="default" isHotelTab={true}>
              <LabelContainer isActive={defaultIndex === 0}>
                <Text
                  aria-label="Search for hotels or destinations"
                  m={0}
                  fontSize="sm"
                  fontWeight="bold"
                  lineHeight={rem(46)}
                  style={{ fontFamily: 'Jetstar' }}
                >
                  Hotels
                </Text>
              </LabelContainer>
            </Tab>
            <Tab
              as={TabButton}
              data-testid="airbnb-tab"
              aria-label="Search for Air BNBs"
              borderRadius="defaultRoundRightOnly"
              isHotelTab={false}
            >
              <LabelContainer isActive={defaultIndex === 1}>
                <Text
                  aria-label="Search for Air BNBs"
                  m={0}
                  fontSize="sm"
                  fontWeight="bold"
                  lineHeight={rem(46)}
                  style={{ fontFamily: 'Jetstar' }}
                >
                  Airbnb
                </Text>
              </LabelContainer>
            </Tab>
          </TabList>
          <SearchWrapper boxShadow={isMobileApp ? 'none' : 'hard'}>
            <TabPanels>
              {/* The <FocusManager /> does not work correctly if it is a child of a parent with tabIndex=0 */}
              <TabPanel hidden={isAirbnbSearch} tabIndex={isAirbnbSearch ? -1 : undefined} data-testid="hotels-panel">
                <HotelsTab />
              </TabPanel>
              <TabPanel hidden={!isAirbnbSearch} tabIndex={!isAirbnbSearch ? -1 : undefined} data-testid="airbnb-panel">
                <AirbnbTab isLandingPage={false} />
              </TabPanel>
            </TabPanels>
          </SearchWrapper>
        </Tabs>
      </TabsWrapper>
    </Box>
  );
};

export default HomePageSearchPanel;
