import React, { useCallback } from 'react';
import { Heading, Image as RooImage, Text, Box } from '@qga/roo-ui/components';
import { useDataLayer } from 'hooks/useDataLayer';
import { getImage } from 'lib/sanity';
import { OfferWrapper, PromotionalSash, OfferCtaLink } from './Offer.style';
import ImageFallback from 'components/Image/ImageFallback';
import Markdown from 'components/Markdown';
import { Offer as Props } from 'types/content';
import getMarkdownForBlockContent from 'lib/sanity/getMarkdownForBlockContent';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';

const Span = styled(Text)`
  display: inline-block;
  font-size: ${themeGet('fontSizes.sm')};
  line-height: 1.75;
  padding-bottom: ${themeGet('space.4')};
`;

const Image = styled(RooImage)`
  object-fit: cover;
`;

const Offer = ({ name, description, callToAction, promotionalSash, image: imageAsset }: Props) => {
  const { name: alt, asset } = imageAsset || {};
  const image = asset ? getImage(asset.asset, { width: 392, height: 280 }) : null;
  const { emitInteractionEvent } = useDataLayer();
  const newTarget = callToAction?.external ? '_blank' : '';
  const newRel = callToAction?.external ? 'noopener noreferrer' : '';

  const handleCallToActionClick = useCallback(() => {
    emitInteractionEvent({
      type: 'Offer Link',
      value: `${name} Selected`,
    });
  }, [emitInteractionEvent, name]);

  return (
    <OfferWrapper width={['100%', '33%']}>
      <Box position="relative" borderRadius="default">
        {promotionalSash && (
          <Box position="absolute" left={0} top={0} style={{ transform: 'translateY(-50%)', maxWidth: '85%' }}>
            <PromotionalSash>{promotionalSash}</PromotionalSash>
          </Box>
        )}
        {image && <Image src={image?.custom} alt={alt || 'An image'} height={['225px', '161px', '225px', '289px']} width="100%" />}
        {!image && <ImageFallback alt="Region image placeholder" height={['225px', '161px', '225px', '289px']} width="100%" />}
      </Box>
      <Heading.h3
        display="block"
        fontSize={['base', 'base', 'lg']}
        mt={[4, 6, 4]}
        mb={2}
        color="greys.charcoal"
        style={{ fontFamily: 'Jetstar', lineHeight: 1.25 }}
      >
        {name}
      </Heading.h3>
      <Box color="greys.iron">
        <Markdown content={getMarkdownForBlockContent(description)} customComponents={{ p: Span, span: Span }} />
      </Box>
      {callToAction && (
        <OfferCtaLink
          data-testid="offer-link"
          to={callToAction?.url}
          href={null}
          rel={newRel}
          target={newTarget}
          onClick={handleCallToActionClick}
        >
          {callToAction?.text}
        </OfferCtaLink>
      )}
    </OfferWrapper>
  );
};

export default Offer;
