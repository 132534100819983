import { Flex, NakedButton, BackgroundImage, Container, Box } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { rem } from 'polished';
import { mediaQuery } from 'lib/styledSystem';
import { TabPanels as ReachTabPanels } from '@reach/tabs';
import isPropValid from '@emotion/is-prop-valid';

export const HeroImage = styled(BackgroundImage)`
  position: absolute;
  top: 0;
  width: 100%;
`;

export const TabsWrapper = styled(Container)`
  position: relative;
  max-width: 1284px;
  margin-left: auto;
  margin-right: auto;
`;

export const SearchWrapper = styled(Flex)`
  background-color: ${themeGet('colors.white')};
  border-radius: 0 0 12px 12px;
  padding: ${themeGet('space.6')} ${themeGet('space.4')};
  margin-bottom: 2px;

  ${mediaQuery.minWidth.sm} {
    border-top-right-radius: 12px;
    padding: ${themeGet('space.8')} ${themeGet('space.10')};
  }

  ${mediaQuery.minWidth.md} {
    border-radius: 0 ${themeGet('radii.exagerated')} ${themeGet('radii.exagerated')} ${themeGet('radii.exagerated')};
  }
`;

export const ResponsiveTabs = styled(Flex)`
  background-color: transparent;
  border-radius: 12px 12px 0 0;
  padding-top: 0;

  ${mediaQuery.minWidth.md} {
    background-color: transparent;
    padding: 0;
  }
`;

export const TabButton = styled(NakedButton)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${themeGet('colors.greys.porcelain')};
  color: ${themeGet('colors.greys.charcoal')};
  height: ${rem('54px')};
  width: 50%;
  opacity: 0.9;
  border-radius: ${(props) => (props.isHotelTab ? '12px 0 0 0' : '0 12px 0 0')};

  &[data-selected] {
    background-color: ${themeGet('colors.white')};
    border-bottom: none;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }

  ${mediaQuery.minWidth.sm} {
    width: ${rem('196px')};
  }
`;

export const TabPanels = styled(ReachTabPanels)`
  width: 100%;
`;

type LabelContainer = {
  isActive: boolean;
};

export const LabelContainer = styled(Box, { shouldForwardProp: isPropValid })<LabelContainer>`
  border-top: 4px solid transparent;
  border-bottom: 4px solid;
  border-bottom-color: ${(props) => (props.isActive ? themeGet('colors.brand.primary') : 'transparent')};
`;
