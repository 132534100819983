import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Icon, Hide, Flex } from '@qga/roo-ui/components';
import { CarouselWrapper, Scroller, ScrollItem, PreviousNavigationButton, NextNavigationButton, BreadcrumbButton } from './Carousel.style';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import Visibility from 'lib/browser/visibility';
import { useDataLayer } from 'hooks/useDataLayer';
import SkipToContentButton from 'components/SkipToContentButton';
import TermsAndConditions from '../../HomePage/FeaturedCampaign/TermsAndConditions';

interface Props {
  width?: string;
  itemList: Array<object>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemRenderer?: React.FC<any>;
  skipToContent?: string;
}

const POLLING_INTERVAL = 20000; // 20 seconds

const Carousel = ({ width = '100%', itemList = [], itemRenderer: ItemRenderer, skipToContent }: Props) => {
  const { emitInteractionEvent } = useDataLayer();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const scrollerRef = useRef<HTMLUListElement>();
  const pollerRef = useRef();
  const enableScrolling = itemList.length > 1;

  const decrementCarousel = useCallback(() => {
    if (scrollerRef?.current) {
      const scroller = scrollerRef.current;
      if (scroller.scrollLeft !== 0 && scroller?.scrollBy) {
        scroller.scrollBy({ left: -scroller.clientWidth, top: 0, behavior: 'smooth' });
      } else if (scroller?.scrollTo) {
        // Scroll to end
        scroller.scrollTo({ left: scroller.scrollWidth, top: 0, behavior: 'smooth' });
      }
    }
  }, [scrollerRef]);

  const incrementCarousel = useCallback(() => {
    if (scrollerRef?.current) {
      const scroller = scrollerRef.current;
      if (scroller.scrollLeft < scroller.scrollWidth - scroller.clientWidth && scroller?.scrollBy) {
        scroller.scrollBy({ left: scroller.clientWidth, top: 0, behavior: 'smooth' });
      } else if (scroller?.scrollTo) {
        // Scroll to start
        scroller.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      }
    }
  }, [scrollerRef]);

  const scrollTo = useCallback(
    (scrollToIndex) => {
      if (scrollerRef?.current && scrollerRef.current?.scrollTo) {
        const scroller = scrollerRef.current;
        scroller.scrollTo({ left: scrollToIndex * scroller.clientWidth, top: 0, behavior: 'smooth' });
      }
    },
    [scrollerRef],
  );

  const previousClick = useCallback(() => {
    decrementCarousel();

    emitInteractionEvent({
      type: 'Featured Offer Carousel',
      value: 'Previous Card Selected (using arrow)',
    });
  }, [decrementCarousel, emitInteractionEvent]);

  const nextClick = useCallback(() => {
    incrementCarousel();

    emitInteractionEvent({
      type: 'Featured Offer Carousel',
      value: 'Next Card Selected (using arrow)',
    });
  }, [incrementCarousel, emitInteractionEvent]);

  const onScrollerUpdated = debounce((event) => {
    const scroller = event.target;
    const index = Math.round((scroller.scrollLeft / scroller.scrollWidth) * itemList.length);
    setSelectedIndex(index);

    // Reset the auto-scroll timer when the user scrolls the carousel
    Visibility.stop(pollerRef.current);
    pollerRef.current = Visibility.every(POLLING_INTERVAL, incrementCarousel);
  }, 100);

  const breadcrumbClicked = useCallback(
    (index) => {
      scrollTo(index);

      emitInteractionEvent({
        type: 'Featured Offer Carousel',
        value: `Card ${index + 1} selected`,
      });
    },
    [scrollTo, emitInteractionEvent],
  );

  useEffect(() => {
    if (enableScrolling) {
      // Start the auto scrolling on mount
      pollerRef.current = Visibility.every(POLLING_INTERVAL, incrementCarousel);
      return () => Visibility.stop(pollerRef.current);
    } else {
      Visibility.stop(pollerRef?.current);
    }
  }, [enableScrolling, pollerRef, incrementCarousel]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedItem = itemList[selectedIndex] as Record<string, any>;

  return (
    <CarouselWrapper maxWidth={width}>
      {skipToContent && (
        <SkipToContentButton as="a" href={skipToContent}>
          Skip to content
        </SkipToContentButton>
      )}

      <Scroller ref={scrollerRef} onScroll={enableScrolling ? onScrollerUpdated : noop} style={{ marginTop: -16, paddingTop: 16 }}>
        {itemList.map((props, index) => (
          <ScrollItem
            key={`carousel-item-${index}`}
            data-testid="carousel-item"
            aria-hidden={index !== selectedIndex}
            inert={index !== selectedIndex ? '' : null}
          >
            <ItemRenderer {...props} />
          </ScrollItem>
        ))}
      </Scroller>

      {enableScrolling && (
        <>
          <Hide xs sm>
            <PreviousNavigationButton onClick={previousClick} aria-label="View previous item" data-testid="prev-carousel-btn">
              <Icon name="chevronLeft" size={20} color="greys.charcoal" />
            </PreviousNavigationButton>
            <NextNavigationButton onClick={nextClick} aria-label="View next item" data-testid="next-carousel-btn">
              <Icon name="chevronRight" size={20} color="greys.charcoal" />
            </NextNavigationButton>
          </Hide>
          <Flex alignItems="center" justifyContent="center" my={[4, 4, 6]}>
            {itemList.map((_, index) => (
              <BreadcrumbButton
                key={`nav-button-${index}`}
                data-testid="breadcrumb-carousel-btn"
                selected={index === selectedIndex}
                onClick={() => breadcrumbClicked(index)}
                aria-label={`View item number ${index}`}
              />
            ))}
          </Flex>
        </>
      )}
      <TermsAndConditions terms={selectedItem?.terms} termsShort={selectedItem?.termsShort} />
    </CarouselWrapper>
  );
};

export default Carousel;
