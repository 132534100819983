import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { darken } from 'polished';
import { Flex, Box, Text, Icon, NakedButton } from '@qga/roo-ui/components';
import { useDataLayer } from 'hooks/useDataLayer';
import { DisclaimerItemLogo } from 'components/Footer/DisclaimerItems';
import { qantasQff } from '@qga/roo-ui/logos';
import { useIsAuthenticated, useLogout } from 'lib/oauth';
import { getFirstName, getLastName, getMemberId, getTitle } from 'store/user/userSelectors';

const Wrapper = styled(Box)`
  background-color: ${themeGet('colors.greys.porcelain')};
  border-radius: 6px;
  height: 65px;
`;

export const QffCTALink = styled('a')`
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  color: ${themeGet('colors.brand.primary')};

  &:hover {
    color: ${(props) => darken(0.1, themeGet('colors.brand.primary', '#FF5115')(props))};
  }

  &:focus {
    outline: ${themeGet('borders.2')} ${themeGet('colors.brand.secondary')};
  }

  font-family: 'Ciutadella';
  font-weight: ${themeGet('fontWeights.bold')};
  font-size: ${themeGet('fontSizes.sm')};
`;

export const QffCTAButton = styled(NakedButton)`
  font-family: 'Ciutadella';
  font-weight: ${themeGet('fontWeights.bold')};
  font-size: 1.125rem;
  color: ${themeGet('colors.brand.primary')};
`;

const truncateText = (text, limit) => {
  return text?.length > limit ? text.substring(0, limit - 1) + '...' : text;
};

const QffWidget = () => {
  const { emitInteractionEvent } = useDataLayer();
  const { logout } = useLogout();

  const isAuthenticated = useIsAuthenticated();
  const qffNumber = useSelector(getMemberId);
  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);
  const title = useSelector(getTitle);

  const qffDetails = `${title} ${firstName} ${lastName}`;
  const qffDetailsString = truncateText(qffDetails, 20);
  const joinForFreeUrl = 'https://www.qantas.com/au/en/frequent-flyer/discover-and-join/join-now.html/code/AIRBNB';
  const eventValue = isAuthenticated ? 'Logout CTA' : 'Join for free CTA';
  const CTAMessage = isAuthenticated ? 'Log out' : 'Join for free';

  const handleOnClick = useCallback(() => {
    emitInteractionEvent({ type: 'Airbnb - Qff', value: eventValue });
  }, [emitInteractionEvent, eventValue]);

  const logoutSSO = useCallback(() => {
    logout();
    handleOnClick();
  }, [logout, handleOnClick]);

  return (
    <Wrapper width={['100%', '100%', '50%']} px={[3, 3, 3]}>
      <Flex data-testid="qff-widget" width={'100%'} justifyContent={isAuthenticated ? 'space-between' : 'center'}>
        <Flex data-testid="logo" alignItems="center" flexGrow={1} maxWidth={isAuthenticated ? '15.875rem' : '7.5rem'}>
          {isAuthenticated ? (
            <>
              <Icon name="roo" color="brand.primary" style={{ marginBottom: '1.125rem' }} />
              <Flex flexDirection={'column'} style={{ flexShrink: 1, gap: '.125rem' }} px={2} py={3} data-testid="member-name">
                <Text
                  fontWeight="bold"
                  fontSize="1.125rem"
                  lineHeight={1.125}
                  style={{ fontFamily: 'Ciutadella', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {qffDetailsString}
                </Text>
                <Text
                  color="greys.steel"
                  lineHeight={1.125}
                  style={{ fontFamily: 'Ciutadella', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {qffNumber}
                </Text>
              </Flex>
            </>
          ) : (
            <Box pt={4}>
              <DisclaimerItemLogo
                src={qantasQff}
                alt="Qantas QFF Logo"
                role="img"
                aria-label="Qantas QFF Logo"
                style={{ marginRight: 0 }}
              />
            </Box>
          )}
        </Flex>
        <Flex pr={1} alignItems="center" justifyContent="flex-end">
          {isAuthenticated ? (
            <QffCTAButton data-testid="log-out-cta" onClick={logoutSSO} style={{ whiteSpace: 'nowrap' }}>
              {CTAMessage}
            </QffCTAButton>
          ) : (
            <>
              <Flex data-testid="qff-widget" alignItems="center" pr={1}>
                <Text style={{ fontFamily: 'Ciutadella', whiteSpace: 'nowrap' }}>Not a member?</Text>
              </Flex>
              <QffCTALink
                data-testid={'join-for-free-cta'}
                href={joinForFreeUrl}
                rel={'noopener noreferrer'}
                target={'_blank'}
                onClick={handleOnClick}
                style={{ whiteSpace: 'nowrap' }}
              >
                {CTAMessage}
              </QffCTALink>
            </>
          )}
        </Flex>
      </Flex>
    </Wrapper>
  );
};

export default QffWidget;
