import React from 'react';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import Markdown from 'components/Markdown';
import { NakedButton, Text, Flex } from '@qga/roo-ui/components';
import Modal from 'components/Modal';
import { useModal } from 'lib/hooks';
import { useDataLayer } from 'hooks/useDataLayer';

interface TermsAndConditionsProps {
  terms?: string;
  termsShort?: string;
}

const LinkButton = styled(NakedButton)`
  text-decoration: underline;
  color: ${themeGet('colors.greys.steel')};
  font-size: ${themeGet('fontSizes.xs')};
  line-height: 20px;
`;

const Span = styled(Text)`
  display: inline-block;
  font-size: ${themeGet('fontSizes.sm')};
  line-height: 1.75;
  padding-bottom: ${themeGet('space.4')};
`;

const TermsAndConditions = ({ termsShort, terms }: TermsAndConditionsProps) => {
  const { emitInteractionEvent } = useDataLayer();
  const { openModal, modalProps } = useModal();

  const handleOpenModal = () => {
    openModal();
    emitInteractionEvent({
      type: 'Featured Offer Carousel',
      value: 'Terms and Conditions Clicked',
    });
  };

  return (
    <>
      <Flex>
        <Text color="greys.steel" fontSize={'xs'} textAlign="start" data-testid="disclaimer-copy">
          {termsShort}
          {terms && (
            <LinkButton onClick={handleOpenModal} ml={[4, 0]} data-testid="terms-and-conditions-button">
              Conditions apply.
            </LinkButton>
          )}
        </Text>
      </Flex>
      {terms && (
        <Modal {...modalProps} fullScreen={false} padding={8} title="Offer Terms and Conditions" footerComponent={null}>
          <Markdown content={terms} customComponents={{ p: Span, span: Span }} />
        </Modal>
      )}
    </>
  );
};

export default TermsAndConditions;
