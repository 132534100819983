import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import MarkdownToJsx from 'markdown-to-jsx';
import { Link, Text } from '@qga/roo-ui/components';
import startCase from 'lodash/startCase';
import { useDataLayer } from 'hooks/useDataLayer';
import { HOTELS_HOST, HOTELS_PATH } from 'config';

const DisclaimerText = styled(Text)`
  display: block;
  color: ${themeGet('colors.greys.steel')};
  font-size: ${themeGet('fontSizes.xs')};
  margin-bottom: ${themeGet('space.1')};
`;

const InlineLink = styled(Link)`
  display: inline;
  text-decoration: underline;
  color: ${themeGet('colors.greys.steel')};
  font-size: ${themeGet('fontSizes.xs')};
`;

const hotelsPropertyRegExp = new RegExp(String.raw`^${HOTELS_HOST}${HOTELS_PATH}/properties/(?:[0-9]*)?`);

const LinkWithTracking = ({ children, href, ...props }) => {
  const { emitInteractionEvent } = useDataLayer();

  const handleClick = () => {
    const isHotelsPropertyLink = hotelsPropertyRegExp.test(href);
    if (children) {
      emitInteractionEvent({
        type: isHotelsPropertyLink ? 'Hotel Text Link' : 'Terms and Conditions Link',
        value: `${startCase(children)} Selected`,
        customAttributes: {
          href,
        },
      });
    }
  };

  return (
    <InlineLink onClick={handleClick} href={href} {...props} rel="noopener noreferrer" target="_blank">
      {children}
    </InlineLink>
  );
};

LinkWithTracking.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
};

LinkWithTracking.defaultProps = {
  href: undefined,
  children: null,
};

const markdownOptions = {
  overrides: {
    a: LinkWithTracking,
    p: DisclaimerText,
    span: DisclaimerText,
  },
};

const TermsMarkdown = ({ content, ...rest }) => {
  return (
    <MarkdownToJsx options={markdownOptions} {...rest}>
      {content}
    </MarkdownToJsx>
  );
};

TermsMarkdown.propTypes = {
  content: PropTypes.string,
};

TermsMarkdown.defaultProps = {
  content: '',
};

export default TermsMarkdown;
