import React, { useEffect } from 'react';
import { Box, Heading, Paragraph } from '@qga/roo-ui/components';
import Markdown from 'components/Markdown';
import getMarkdownForBlockContent from 'lib/sanity/getMarkdownForBlockContent';
import { FeaturedOfferCard, Cta } from './FeaturedCampaignBanner.style';
import { FeaturedOfferBanner as FeaturedOfferBannerProps } from 'types/content';
import { useDataLayer } from 'hooks/useDataLayer';
import { getGclid } from 'components/HomePage/sessionStorage';

const FeaturedOfferBanner = ({ heading, description, callToAction }: FeaturedOfferBannerProps) => {
  const { to, text } = callToAction;
  const { emitInteractionEvent } = useDataLayer();
  const gclid = getGclid();

  const handleCtaClick = () => {
    emitInteractionEvent({
      type: 'Featured Offer Carousel',
      value: 'Call To Action Selected',
    });
  };

  useEffect(() => {
    if (gclid && to) {
      callToAction.to = `${to}?gclid=${gclid}`;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FeaturedOfferCard>
      {heading && (
        <Heading.h2 fontSize={['base', 'base', 'lg']} lineHeight={['22px', '22px', 1.25]} mb={[4, 4, 2]} style={{ fontFamily: 'Jetstar' }}>
          {heading}
        </Heading.h2>
      )}
      {description && (
        <Box data-testid="offer-description" mb={4}>
          <Markdown
            content={getMarkdownForBlockContent(description)}
            customComponents={{
              span: { component: Paragraph, props: { lineHeight: 1.75 } },
            }}
          />
        </Box>
      )}
      {callToAction && (
        <Cta to={to} href={callToAction?.href} target="" onClick={handleCtaClick} data-testid="carousel-offer-cta">
          {text}
        </Cta>
      )}
    </FeaturedOfferCard>
  );
};

export default FeaturedOfferBanner;
