import { AIRBNB_INTERSTITIAL_PAGE_URL } from 'config';
import { useIsAuthenticated } from 'lib/oauth';
import { useOAuthState } from 'lib/oauth/useOAuthState';
import { loginQffUser, useLoginUrl } from 'lib/qffAuth';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTreatments } from 'store/split/splitSelectors';

export default function useAirbnbRedirect() {
  const router = useRouter();
  const splitTreatments = useSelector(getTreatments);
  const isOAuthEnabled = splitTreatments?.login_service?.treatment === 'oauth';
  const [searchParams, setSearchParams] = useState('');
  const isAuthenticated = useIsAuthenticated();
  const { loginUrl } = useLoginUrl();
  const [state] = useOAuthState();

  useEffect(() => {
    if (isAuthenticated && searchParams.length) {
      router.push(`${AIRBNB_INTERSTITIAL_PAGE_URL}?${searchParams}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, searchParams]);

  const handleAirbnbRedirect = (query: string) => {
    if (!isAuthenticated) {
      if (isOAuthEnabled) {
        const url = JSON.stringify(`/airbnb/redirect?${query}`);
        window.localStorage.setItem(state, url);
        router.push(loginUrl);
      } else {
        setSearchParams(query);
        loginQffUser();
      }
    } else {
      router.push(`${AIRBNB_INTERSTITIAL_PAGE_URL}?${query}`);
    }
  };

  return { handleAirbnbRedirect };
}
