import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { Card, Flex } from '@qga/roo-ui/components';
import { rem } from 'polished';
import { mediaQuery } from 'lib/styledSystem';
import AppLink from 'components/AppLink/AppLink';

export const FeaturedOfferCard = styled(Card)`
  width: 100%;
  border-radius: ${themeGet('radii.defaultRoundBottomOnly')};
  padding: ${themeGet('space.8')} ${themeGet('space.6')} ${themeGet('space.10')};

  ${mediaQuery.minWidth.md} {
    position: absolute;
    top: 90px;
    left: 90px;
    max-width: ${rem(490)};
    box-shadow: ${themeGet('shadows.primary')};
    padding: ${themeGet('space.8')} ${themeGet('space.8')};
    border-radius: ${themeGet('radii.default')};
  }
`;

export const Cta = styled(AppLink)`
  display: block;
  text-align: center;

  ${themeGet('mediaQueries.0')} {
    display: inline-block;
    min-width: 339px;
  }

  margin: 0;
  padding: ${themeGet('space.3')} ${themeGet('space.6')};

  outline: 0;
  transition-property: color, background-color, border-color;
  transition-duration: 200ms;
  transition-timing-function: ease-in;
  cursor: pointer;
  appearance: none;

  &:focus {
    box-shadow: 0 0 0 2px ${themeGet('colors.brand.secondary')};
  }

  &:disabled {
    background-color: ${themeGet('colors.greys.alto')};
    border-color: ${themeGet('colors.greys.alto')};
    color: ${themeGet('colors.greys.dusty')};
    cursor: not-allowed;
  }

  color: ${themeGet('colors.brand.primary')} !important;
  background: transparent;
  font-size: ${themeGet('fontSizes.sm')};
  font-weight: ${themeGet('fontWeights.bold')};
  line-height: ${themeGet('lineHeights.loose')};

  &:first-letter {
    text-transform: uppercase;
  }

  border-style: solid;
  border-width: 2px;
  border-color: ${themeGet('colors.brand.primary')};
  border-radius: 999999px;
  padding: ${themeGet('space.3')} ${themeGet('space.7')};

  &:hover {
    color: white !important;
    background: #e13a00;
    border-color: #e13a00;
  }
`;

export const Badge = styled(Flex)`
  align-items: center;
  justify-content: center;
`;
