import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { getImage } from 'lib/sanity';
import { Text, Flex, Heading, Image } from '@qga/roo-ui/components';
import { mediaQuery } from 'lib/styledSystem';

const ValuePropositionStandardWrapper = styled(Flex)`
  flex: 1 1 0;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
`;

const ValuePropositionSlimlineWrapper = styled(Flex)`
  flex: 1 1 0;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.5rem;

  ${mediaQuery.minWidth.sm} {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    max-width: 263px;
  }
`;

const ValuePropositionStandard = ({ title, iconName, description }) => {
  const { asset, caption } = iconName;
  const image = getImage(asset);

  return (
    <ValuePropositionStandardWrapper data-testid="standard-layout" alignItems={['flex-start']}>
      <Image alt={caption} display="block" src={image.small} height={48} width="auto" />
      <Flex flexDirection={['column', 'column', 'row']} flexWrap="wrap" width="100%">
        <Heading display="block" fontSize="base" lineHeight="22px" color="greys.iron" mb={0} style={{ fontFamily: 'Jetstar' }}>
          {title}
        </Heading>
        <Text display="block" fontSize={14} mt={1}>
          {description}
        </Text>
      </Flex>
    </ValuePropositionStandardWrapper>
  );
};

ValuePropositionStandard.propTypes = {
  title: PropTypes.string.isRequired,
  iconName: PropTypes.object,
  description: PropTypes.string,
};

ValuePropositionStandard.defaultProps = {
  iconName: null,
  description: '',
};

const ValuePropositionSlimline = ({ title, iconName }) => {
  const { asset, caption } = iconName;
  const image = getImage(asset);

  return (
    <ValuePropositionSlimlineWrapper data-testid="slimline-layout">
      <Image alt={caption} display="block" src={image.small} height={[24, 48]} width={[24, 48]} />
      <Heading
        display="block"
        fontSize="base"
        lineHeight="22px"
        color="greys.iron"
        textAlign={['start', 'center']}
        style={{ fontFamily: 'Jetstar', margin: 0 }}
      >
        {title}
      </Heading>
    </ValuePropositionSlimlineWrapper>
  );
};

ValuePropositionSlimline.propTypes = {
  title: PropTypes.string.isRequired,
  iconName: PropTypes.object,
};

ValuePropositionSlimline.defaultProps = {
  iconName: null,
};

const ValuePropositionsList = ({ title, valuePropositions, layout }) => (
  <Flex flexDirection="column" style={{ columnGap: '1.5rem', rowGap: layout === 'slimline' ? '1.5rem' : '2rem' }}>
    {typeof title === 'string' && (
      <Heading.h1
        mx={[0, 'auto']}
        mb={0}
        fontSize={['md', '28px']}
        lineHeight={[1.25, '35px']}
        textAlign={['start', 'center']}
        color="greys.iron"
        style={{ fontFamily: 'Jetstar' }}
      >
        {title}
      </Heading.h1>
    )}
    <Flex
      flexDirection={['column', 'row']}
      flexWrap="space-between"
      justifyContent="center"
      mx={layout === 'slimeline' && 'auto'}
      data-testid="elements-wrapper"
      style={{ gap: '1.5rem' }}
    >
      {layout === 'standard' &&
        valuePropositions?.map((item) => (
          <ValuePropositionStandard key={item.title} title={item.title} iconName={item.iconName} description={item.description} />
        ))}
      {layout === 'slimline' &&
        valuePropositions?.map((item) => <ValuePropositionSlimline key={item.title} title={item.title} iconName={item.iconName} />)}
    </Flex>
  </Flex>
);

ValuePropositionsList.propTypes = {
  title: PropTypes.string.isRequired,
  valuePropositions: PropTypes.array.isRequired,
  layout: PropTypes.string,
};

ValuePropositionsList.defaultProps = {
  layout: 'standard',
};

export default ValuePropositionsList;
