import React from 'react';
import { Box } from '@qga/roo-ui/components';
import { getImage } from 'lib/sanity';
import { FeaturedOffer as FeaturedOfferProps } from 'types/content';
import { PromotionalSash } from 'components/Content/OffersList/Offer/Offer.style';

import FeaturedOfferBanner from './FeaturedCampaignBanner';
import { FeaturedOfferWrapper, HeroImage } from './FeaturedCampaign.style';

const FeaturedCampaign = ({ backgroundImage, callToAction, name, offerDescription, promotionalSash }: FeaturedOfferProps) => {
  const { asset, name: backgroundImageName } = backgroundImage;
  const backgroundImageSrc = getImage(asset);

  return (
    <FeaturedOfferWrapper mx={[4, 0]}>
      {promotionalSash && (
        <Box position="absolute" left={[0, 0, 90]} top={[0, 0, 90]} zIndex={1} style={{ transform: 'translateY(-50%)' }}>
          <PromotionalSash fontWeight="bold" data-testid="promotional-sash" style={{ fontFamily: 'Jetstar, Helvetica Neue, sans-serif' }}>
            {/* TODO add icon? */}
            {promotionalSash}
          </PromotionalSash>
        </Box>
      )}
      <HeroImage src={backgroundImageSrc?.medium} alt={backgroundImageName} role="img" height={['233px', '441px', '584px']} />
      <FeaturedOfferBanner heading={name} description={offerDescription} promotionalSash={promotionalSash} callToAction={callToAction} />
    </FeaturedOfferWrapper>
  );
};

export default FeaturedCampaign;
