import styled from '@emotion/styled';
import { Box, Flex } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import { mediaQuery } from 'lib/styledSystem';
import AppLink from 'components/AppLink';

export const OfferWrapper = styled(Flex)`
  flex-direction: column;
  margin-bottom: ${themeGet('space.10')};
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
    flex-grow: 1;
  }

  ${mediaQuery.minWidth.sm} {
    margin-bottom: 0;
    margin-right: ${themeGet('space.6')};

    &:last-of-type {
      margin-right: 0;
      flex-grow: 1;
    }
  }

  ${mediaQuery.minWidth.md} {
    &:last-of-type {
      margin-right: 0;
    }
  }

  ${mediaQuery.minWidth.lg} {
    margin-right: 30px;
  }
`;

export const PromotionalSash = styled(Box)`
  background-color: ${themeGet('colors.brightSun')};
  padding: ${themeGet('space.1')} ${themeGet('space.4')};
  border-radius: 16px;
  border-bottom-left-radius: 0;
  font-family: Jetstar;
  font-size: ${themeGet('fontSizes.xs')};
  line-height: 20px;
  font-weight: ${themeGet('fontWeights.bold')};
  text-transform: uppercase;
`;

export const OfferCtaLink = styled(AppLink)`
  font-weight: ${themeGet('fontWeights.bold')};
  line-height: 1.5;
`;
